<template>
  <v-container class="bg-color-theme px-1">
    <validation-observer :ref="'observer'">
      <v-form
        ref="form"
        @submit.prevent="updateItem"
        lazy-validation
        autocomplete="off"
      >
        <v-card elevation="2" class="py-9 px-4">
          <v-row class="mb-4">
            <v-col cols="12" class="py-0">
              <validation-provider v-slot="{ errors }" rules="required" name="">
                <v-label>
                  <span class="font-13px text-a9">
                    表示名
                  </span>
                </v-label>
                <v-text-field
                  class="font-14px"
                  placeholder="中沢さん"
                  v-model="name"
                  :error-messages="errors"
                  autocomplete="chrome-off"
                  dense
                  :disabled="disabled"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" class="py-0">
              <validation-provider
                v-slot="{ errors }"
                rules="required|email"
                name="email"
              >
                <v-label>
                  <span class="font-13px text-a9">
                    メールアドレス
                  </span>
                </v-label>
                <v-text-field
                  class="font-14px"
                  placeholder="mail@redish.com"
                  v-model="email_address"
                  :error-messages="errors"
                  autocomplete="chrome-off"
                  dense
                  :disabled="disabled"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <div class="d-flex align-center justify-space-between mt-5">
            <v-btn
              elevation="3"
              outlined
              color="white"
              class="primary--text btn-blue-outline"
              width="145px"
              @click="deleteItem"
              :loading="loading"
              :disabled="disabled" 
              >削除する</v-btn
            >
            <v-btn color="primary" width="145px" type="submit" elevation="3" :loading="loading" :disabled="disabled">更新する</v-btn
            >
          </div>
        </v-card>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EditMail",
  data() {
    return {
      errors: "",
      loading:false,
      email_address: "",
      name: "",
      disabled:false
    };
  },
  props:['id'],
  computed: {
    ...mapGetters(["getMailingList"])
  },
  created(){
    this.getDataFromApi();
    if(this.$store.getters.getShopAuthUser[0].user_role == "P"){
      this.disabled = true;
    }
  },
  methods: {
    async getDataFromApi(){
      this.loading = true;
      let params = {};
      params.shop_id=this.$store.getters.getFirstShopObject.id;
      params.id = this.id;
      await this.$store.dispatch('MAILINGLIST_GET', params).then(() => {
        this.loading = false,
        this.patchEditFormValues() 
      })
    },

    patchEditFormValues(){
        this.email_address=this.getMailingList.email_address;
        this.name=this.getMailingList.name;
    },

    deleteItem() {
      this.$root.confirm
        .open("このメールアドレスを\nメール通知リストから削除しますか？")
        .then(res => {
          if (res) {

            let params = {};
            params.shop_id=this.$store.getters.getFirstShopObject.id;
            params.mailing_list_id = this.id;

            this.$store.dispatch('MAILINGLIST_DELETE', params).then(
              response => {
                if (response.data.data.status == 'success') {
                  
                  this.$router.push({ path: "/mailing-list" });
                  this.$store.dispatch("SHOW_ALERT", {
                    text: "メーリングリストを削除しました。",
                    successStatus: "info"
                  });
                }
              },
              error => {
                throw error
              }
            )
          }
        });
    },

    updateItem() {
      this.$refs.observer.validate().then(success => {
          if (!success) {
            return
          }

          let params = {};
          let shop_id=this.$store.getters.getFirstShopObject.id;
          let mailing_list_id = this.id;
          params.name=this.name;
          params.email_address=this.email_address;

          this.$store.dispatch('MAILINGLIST_UPDATE', {params,shop_id,mailing_list_id}).then(
            response => {
              if (response.data.data.status == 'success') {
                
                this.$router.push({ path: "/mailing-list" });
                this.$store.dispatch("SHOW_ALERT", {
                  text: "編集内容を保存しました。",
                  successStatus: "info"
                });
              }
            },
            error => {
              if (error?.data?.errors)
              this.$refs.observer.setErrors(error?.data?.errors);
            }
          )
      })
    }
  }
};
</script>
